import country from './country/ja';

export default {
  translation: {
    ...country,
    'Express checkout': 'エクスプレスチェックアウト',
    'Or fill in the information': 'または情報を入力',
    'Form Validation - required': '{{fieldName}}は必須です',
    'Form Validation - max': '{{fieldName}} が長すぎます',
    'Form Validation - maxLength': '{{fieldName}} が長すぎます',
    'Form Validation - min': '{{fieldName}} が短すぎます',
    'Form Validation - pattern': '{{fieldName}} は無効です',
    'Form Validation - validate': '{{fieldName}} は無効です',
    'Form Validation - birthday - validate - pattern':
      '{{fieldName}} は無効です',
    'Form Validation - validate - minAge':
      '生年月日が無効です。{{minAge}} 歳以上である必要があります',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} は無効です。再度確認してください',
    'Form Validation - terms':
      'サイトの利用規約およびプライバシーポリシーに同意する必要があります',
    'Form Action Deleting': '削除中',

    IAgreeTerms:
      '私は<0>店舗の利用規約</0>および<2>プライバシーポリシー</2>に同意します',
    'Terms of Use and Privacy Policy and Age Policy':
      '私はウェブサイト<a href="{{ link }}">利用規約およびプライバシー ポリシー</a>に同意します。また、私は {{ age }} 歲以上です',
    Terms: '<0>店舗の利用規約</0>および<2>プライバシーポリシー</2>',
    'Ask Signin': 'すでに登録？',
    'Member Login': 'ログイン',
    Login: 'ログイン',
    'Customer Information': 'お客様の情報',
    'Full Name': 'フルネーム',
    'Email Address': 'メールアドレス',
    'Email Address Optional': 'メールアドレス（任意）',
    'Create an account with shop': '{{shopName}} のアカウントを作成',
    'Send email updates and promotions':
      '{{merchantName}} からショップの最新情報、プロモーション、その他のマーケティング情報を受け取ることに同意します',
    'Shipping Address': 'お届け先住所',
    'Recipient Name': 'お届け先のお名前',
    'Recipient Contact Number': '電話番号',
    'Customer Contact Number': '顧客電話番号',
    'Address Line 1': '住所（1',
    'Address Line 1 DE': '住所（1',
    'Address Line 2': 'アドレス 2 (オプション)',
    'Address Line 2 DE': 'アドレス 2 (オプション)',
    Address: 'Address',
    'Shipping Destination': '配送場所',
    'US Shipping Destination': '配送場所',
    City: '市・町',
    State: '州・県・区',
    'US State': '州・県・区',
    Zipcode: '郵便番号',
    'Zipcode GB': '郵便番号',
    'Zipcode FR': '郵便番号',
    'Zipcode DE': '郵便番号',
    'Payment Detail': 'お支払いの詳細',
    'Payment Fee': '支払い手数料',
    'Total Order Over Hint':
      'この注文は最低金額{{amount}}以上からお支払いすることができます',
    'Delivery Method': '配送方法',
    'Delivery Fee': '配送料',
    'Remarks for Shop': 'その他の情報',
    'Remarks for Shop Placeholder':
      'ご注文に関して備考がありましたら記入してください（任意）',
    'Cart Title': '商品リスト',
    'Cart Total': '合計',
    'Cart Subtotals subtotal': '小計',
    'Cart Subtotals discount': '割引の適用',
    'Cart Subtotals total_tax_fee': '税額',
    'Cart Subtotals applied_user_credits': 'ショッピングクレジット割引',
    'Cart Subtotals delivery_fee': '送料',
    'Cart Subtotals payment_fee': '支払い手数料',
    'Cart Subtotals custom_discount': '割引き（カスタム）',
    'Cart Subtotals applied_member_point_redeem_to_cash': 'ポイントで現金割引',
    'Cart delivery_fee Free': '送料無料',
    'Cart delivery_fee Partial Free': '一部商品送料無料',
    'Cart Coupon code apply success': 'クーポン使用済み',
    'Cart Affiliate code apply success': 'アフィリエイトコードが適用されました',
    'Place Order': '注文を送信',
    'Promotion Label - Promotion': 'プロモーション',
    'Promotion Label - Subscription Promotion': '定期購入割引',
    'Promotion Label - Credit Reward': 'ショッピングボーナス',
    'Promotion Label - Point Reward': 'Reward Points',
    'Promotion Label - Earn Purchase Points': 'Earning Points',
    'Promotion Label - Bundle Pricing': 'バンドル',
    'Promotion Label - Bundle Group': 'バンドルグループ',
    'Promotion Label - Buy X Get Y': 'A+Bの組み合わせ',
    'Promotion Label - Member Point Redeem Gift': 'ポイントキャンペーン',
    'Promotion Label - Member Tier Promotion': '会員ランク割引',
    'Custom Discount Item Label': '割引き',
    'Max Discount Price custom_discount': '最大割引',
    'Apply Code': '認証',
    'Enter Coupon Code': 'クーポンコードを入力',
    'Enter Affiliate Code': 'アフィリエイトコードを適用',
    'Free Checkout': '無料決済',
    'Form Error - Not Found Address':
      '入力いただいた住所は現在配達範囲外です。',
    'Form Error - Not Found Delivery':
      '入力いただいた住所は現在配達範囲外です。再入力の上、配送方法を選択してください。',
    'Form Error - Card Type Not Support':
      'この種類のクレジットカードは現在利用できません。',
    'Credit Card Holder Name': 'クレジットカード名義人名',
    'Credit Card Number': 'クレジットカード番号',
    'Credit Card Expiry Date': '有効期限 (MM/YY)',
    'Credit Card CVC': 'セキュリティコード（CVC）',
    'SlPayment Credit Card Holder Name Hint': 'クレジットカードと同じ名前',
    'SlPayment Credit Card Expiry Date': '有効期限',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      '注文にエラーがあります。入力内容を確認の上、再度お試しいただくかストアまでお問い合わせください。',
    'Payment 3D Verification Failed':
      '3D 認証に失敗したため注文を実行できませんでした。もう一度お試しいただくか、別のお支払い方法を選択してください。',
    'CVC Hint': '通常、クレジットカード裏面に記載されている3桁のコードです',
    'Product Quantity Validation - Sold Out':
      '完売しました。<1>商品を削除してください</1>',
    'Product Quantity Validation - Invalid Format': '1 以上必要',
    'Product Quantity Validation - Low Inventory': '在庫あと {{ value }} 点',
    'Product Quantity Validation - Purchase Limit':
      '1回の注文で購入 {{ value }} 点まで',
    'Product Quantity Validation - Quantity Over Inventory': '在庫オーバー',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      'おひとり様あたり購入 {{limit}} 点まで',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      'おひとり様あたり購入 {{limit}} 点まで。\nあと{{ left }}点',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      'おひとり様あたり購入 {{limit}} 点まで。\n上限に達しました',
    'Product Quantity Validation - Invalid':
      '期限切れです。<1>商品を削除してください</1>',
    'Product Quantity Validation - Insufficient Point':
      'ポイントが不足しています。<1>商品を削除してください</1>',
    'Points used to redeem gifts': 'ギフト交換に使用されたポイント',
    'Points earned after days':
      '獲得ポイント {{number}} 日間が受取日より経過してから',
    'Points earned after order completed': '注文完了後ポイント獲得',
    'Credits earned after order completed':
      '注文完了後ショッピングクレジット獲得',
    Points: 'ポイント',
    Credits: 'ショッピングクレジット',
    'Free Gift': '無料ギフト',
    'Remove Product Popup - title': '商品を削除',
    'Remove Product Popup - content': '{{ itemName }} を削除しますか?',
    'Remove Product Popup - cancel': 'キャンセル',
    'Remove Product Popup - remove': '削除',
    'Add-On Item Validation - Purchase Limit':
      '追加商品数はメイン商品を超えることはできません',
    'Product(s) ordered from livestream are reserved':
      'ライブ販売限定での商品在庫です。カウントダウン <0>{{ countdown }}</0> この機会にぜひお買い求めください！',
    'Live Stream limited time reservation':
      'ライブ販売限定で 在庫{{ qty }} 点です',
    'Live Stream limited time reservation (separator)': '、',
    'Live Stream limited time reservation (unit)': '{{ qty }}点',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till':
      'ライブ販売 ({{ startDateFormat }} から {{ endDateFormat }}まで： {{ title }}) 現地のタイムゾーンにもとづいて表示されます (GMT{{ offset }})',
    'Inclusive Tax Hint': '税込み {{taxName}} {{taxRate}}%: {{taxAmount}}',
    'SL FPX Payment Hint':
      '決済には SHOPLINE Payments をご利用いただきます。決済が完了すると SHOPLINE Payments の<a href="/about/terms" target="_blank">利用規約</a>およびFPXの<a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">利用規約</a>に同意したことになります。',
    'SL FPX Checkout Term':
      '次のステップで銀行ページに接続されます。完了後、必ず「支払いの確認」をクリックし、注文ページに戻って注文の完了を確認してください。',
    'Select Bank': '銀行を選択してください',
    'Field (optional)': '{{fieldName}} (オプション)',
    'Field - gender': '性別',
    'Field - birthday': '生年月日',
    'Gender - male': '男性',
    'Gender - female': '女性',
    'Gender - other': '回答なし',
    'Birthday - hint':
      'オンラインストアで登録して買い物をするには、一定の年齢以上である必要があります。',
    'Birthday - year': '年',
    'Birthday - month': '月',
    'Birthday - date': '日',
    'Saved Addresses - new': '新しい住所',
    'Saved Addresses - default': '既定の住所',
    'Saved Addresses - saved address': '保存済みの先住所',
    'Saved Addresses - save address': 'この新しい住所をアドレス帳に保存する',
    'Add To Cart': 'ショッピングカートに追加',
    'Add To Cart Success': 'カートにアイテムを追加しました',
    'Addon Reminder': '以下の商品も追加で購入できます。',
    'Addon Limit': '購入 {{limit}} 点限り',
    'Reach Purchase Limit': '上限に達しました',
    'Sold Out': '売り切れ',
    'Addon Min Price':
      '注文小計が {{minPrice}} に達すると、以下の商品を割引価格で購入できます。',
    'Addon Item': '追加商品',
    'Cart Over Limit Error':
      '商品合計数は上限 100 個に制限されています。数量を調整してからご購入ください。',
    Cancel: 'キャンセル',
    Continue: '続ける',
    'Auto Fill - Title':
      'エクスプレスチェックアウト用の携帯電話番号を入力してください',
    'Auto Fill - Phone Error': '正しい形式で入力してください',
    'Auto Fill - Toast':
      '以前の注文履歴が検出されない場合、エクスプレスチェックアウトのデータは取り込まれません。',
    QuickRegistrations_creditValueDescription:
      '以下の会員情報の入力にご協力ください。入力が完了すると、${{ CreditValue }} のショッピング クレジットが付与されます。',
    QuickRegistrations_isVerified: '確認済み',
    QuickRegistrations_isUnverified: '未検証',
    QuickRegistrations_Input_Phone_Invalid_Format:
      '正しい携帯電話形式を入力してください',
    QuickRegistrations_Input_Email_Invalid_Format:
      '正しいメールアドレスを入力してください',
    QuickRegistrations_Input_Password_Required:
      'オンラインストアのログインパスワードが必要です',
    QuickRegistrations_Input_Password_Too_Short: 'パスワードが短すぎます',
    QuickRegistrations_Input_Password_Too_Long: 'パスワードが長すぎます',
    QuickRegistrations_Input_Password_Complexity:
      'パスワードは 8 文字以上である必要があります (スペースは含まれません)\nパスワードは少なくとも 1 文字の大文字の英字である必要があります\nパスワードは少なくとも 1 文字の小文字の英字である必要があります\nパスワードは最低1つの数字\nパスワードには少なくとも 1 つの特殊記号 (例: ! # $ ^ *) が含まれている必要があります。',
    QuickRegistrations_Input_Phone_Placeholder:
      '携帯電話番号を入力してください (必須)',
    QuickRegistrations_Input_Email_Placeholder:
      'メールアドレスを入力してください',
    QuickRegistrations_Input_Password_Placeholder:
      'オンラインストアログインパスワード（8文字以上）',
    QuickRegistrations_Input_Name_Placeholder:
      'フルネームを入力してください (必須)',
    QuickRegistrations_Input_Phone_Label: '電話番号',
    QuickRegistrations_Input_Email_Label: '電子メール',
    QuickRegistrations_Input_Password_Label: 'パスワード',
    QuickRegistrations_Input_Name_Label: 'メンバー名',
    QuickRegistrations_Input_Birthday: '誕生日',
    QuickRegistrations_Input_Birthday_Year_Placeholder: '年',
    QuickRegistrations_Input_Birthday_Month_Placeholder: '月',
    QuickRegistrations_Input_Birthday_Day_Placeholder: '日',
    QuickRegistrations_Input_Gender_Female: '女性',
    QuickRegistrations_Input_Gender_Male: '男',
    QuickRegistrations_Input_Gender_Other: '開示されていない',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }} (必須)',
    QuickRegistrations_Submit: '送り出す',
    QuickRegistrations_Modal_getCreditSuccess:
      'ショッピングボーナスを正常に受け取りました',
    QuickRegistrations_Modal_getCreditAlready:
      'すでにショッピング クレジットを受け取っている',
    QuickRegistrations_Modal_BindingLINEFailed_title: 'バインドに失敗しました',
    QuickRegistrations_Modal_BindingLINEFailed_body:
      'この LINE アカウントは以前に公式 LINE アカウントにバインドされており、再度バインドすることはできません',
    QuickRegistrations_Modal_Social_User_Binding_Error:
      '購読に失敗しました。ストアにお問い合わせください',
    QuickRegistrations_Modal_Social_User_Double_Binding_Error:
      'このソーシャル アカウントは別の顧客にバインドされています。バインドを解除するにはストアにお問い合わせください。',
    QuickRegistrations_Modal_Platform_Id_Different_Error:
      '購読に失敗しました。このメンバーを登録した LINE アカウントを使用して購読してください。',
    QuickRegistrations_Modal_Platform_User_Id_Signed_Up_By_Others_Error:
      '登録に失敗しました。この LINE アカウントは他のメンバーによって登録されています。LINE を使用してアカウントにログインし、再度登録してください。',
    QuickRegistrations_Modal_Line_Error:
      '登録に失敗しました。再度LINEにログインしてください',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: 'もちろん',
    QuickRegistrations_already_used: 'すでに他のメンバーが使用しています',
    QuickRegistrations_custom_field_is_required: '{{ fieldName }} は必須です',
    QuickRegistrations_minimum_age_limit:
      '年齢制限は {{ age }} 歳以上である必要があります',
    QuickRegistrations_Member_Info_Hint:
      '次のメンバー情報を入力してください: {{ fields }}、入力完了後に {{ rewards }} を受け取ります。',
    QuickRegistrations_Member_Info_Sent:
      '情報チェック完了後、会員情報特典を発行！',
    QuickRegistrations_Member_Info_Symbol: '、',
    QuickRegistrations_Member_Info_Name: '名前',
    QuickRegistrations_Member_Info_Email: '電子メール',
    QuickRegistrations_Member_Info_Mobile: '電話番号',
    QuickRegistrations_Member_Info_Gender: '性別',
    QuickRegistrations_Member_Info_Birthday: '誕生日',
    QuickRegistrations_Member_Info_User_Credits:
      '${{ count }} 元のショッピング クレジット',
    QuickRegistrations_Member_Info_Member_Points:
      '{{ count }} ポイント メンバーシップ ポイント',
    QuickRegistrations_Member_Info_Coupons: '{{ count }} クーポン',
  },
  widget: {
    Subscribed: '登録完了',
    'Invalid email': 'メールの形式が間違っています',
    'Please try again': 'もう一度試してください',
    'You have requested too many times, please try again later':
      '複数回実行しました。しばらくしてからもう一度試してください。',
    'Clear All Filter': 'すべてクリア',
  },
};
