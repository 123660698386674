import country from './country/zh-hant';

export default {
  translation: {
    ...country,
    'Express checkout': '快速結帳',
    'Or fill in the information': '或填寫信息',
    'Form Validation - required': '{{fieldName}} 是必須的',
    'Form Validation - max': '{{fieldName}} 太長',
    'Form Validation - maxLength': '{{fieldName}} 太長',
    'Form Validation - min': '{{fieldName}} 太短',
    'Form Validation - pattern': '{{fieldName}} 無效',
    'Form Validation - validate': '{{fieldName}} 無效',
    'Form Validation - birthday - validate - pattern': '{{fieldName}} 無效',
    'Form Validation - validate - minAge': '生日無效，需大於 {{minAge}} 歲',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} 無效，請重新確認',
    'Form Validation - terms': '你必須同意網站服務條款及隱私政策',
    'Form Action Deleting': '刪除中',
    IAgreeTerms: '我同意網站<0>服務條款</0>及<2>隱私政策</2>',
    'Terms of Use and Privacy Policy and Age Policy':
      '我同意網站<0>服務條款</0>及<2>隱私權政策</2>，且我已滿 {{ age }} 歲',
    Terms: '<0>服務條款</0>及<2>隱私政策</2>',
    'Ask Signin': '以會員身分快速結帳？',
    'Member Login': '會員登入',
    Login: '登入',
    'Customer Information': '顧客資料',
    'Full Name': '顧客名稱',
    'Email Address': '電子信箱',
    'Email Address Optional': '電子信箱 (選填)',
    'Create an account with shop': '成為 {{shopName}} 的會員',
    'Send email updates and promotions':
      '我願意接收 {{merchantName}} 的最新消息、優惠及服務推廣相關資訊',
    'Shipping Address': '送貨資料',
    'Recipient Name': '收件人名稱',
    'Recipient Contact Number': '收件人電話號碼',
    'Customer Contact Number': '顧客電話號碼',
    'Address Line 1': '地址1',
    'Address Line 1 DE': '地址1',
    'Address Line 2': '地址2 (選填)',
    'Address Line 2 DE': '地址2 (選填)',
    Address: '地址',
    'Shipping Destination': '送貨地點',
    'US Shipping Destination': '送貨地點',
    City: '城市/市鎮',
    State: '州/省/地區',
    'US State': '州/省/地區',
    Zipcode: '郵編區號',
    'Zipcode GB': '郵編區號',
    'Zipcode FR': '郵遞區號',
    'Zipcode DE': '郵編區號',
    'Payment Detail': '付款資料',
    'Payment Fee': '附加費',
    'Total Order Over Hint': '此訂單須符合最低金額 {{amount}}，才能進行結帳。',
    'Delivery Method': '送貨方式',
    'Delivery Fee': '運費',
    'Remarks for Shop': '其他資訊',
    'Remarks for Shop Placeholder': '有什麼想告訴賣家嗎？ (選填)',
    'Cart Title': '商品清單',
    'Cart Total': '合計',
    'Cart Subtotals subtotal': '小計',
    'Cart Subtotals discount': '折扣',
    'Cart Subtotals total_tax_fee': '稅費',
    'Cart Subtotals applied_user_credits': '折抵購物金',
    'Cart Subtotals delivery_fee': '運費',
    'Cart Subtotals payment_fee': '附加費',
    'Cart Subtotals custom_discount': '自訂折扣合計',
    'Cart Subtotals applied_member_point_redeem_to_cash': '使用點數折現',
    'Cart delivery_fee Free': '免運',
    'Cart delivery_fee Partial Free': '部分免運',
    'Cart Coupon code apply success': '已使用優惠券',
    'Cart Affiliate code apply success': '推薦人已套用',
    'Place Order': '提交訂單',
    'Promotion Label - Promotion': '優惠促銷',
    'Promotion Label - Subscription Promotion': '定期購優惠',
    'Promotion Label - Credit Reward': '購物金回饋',
    'Promotion Label - Point Reward': '點數回饋',
    'Promotion Label - Earn Purchase Points': '消費集點',
    'Promotion Label - Bundle Pricing': '任選優惠',
    'Promotion Label - Bundle Group': 'A+B 組合',
    'Promotion Label - Buy X Get Y': '買Ｘ享Ｙ',
    'Promotion Label - Member Point Redeem Gift': '兌換活動',
    'Promotion Label - Member Tier Promotion': '會員優惠',
    'Custom Discount Item Label': '自訂折扣',
    'Max Discount Price custom_discount': '最多折扣',
    'Apply Code': '驗證',
    'Enter Coupon Code': '使用優惠代碼',
    'Enter Affiliate Code': '使用推薦代碼',
    'Free Checkout': '免費結帳',
    'Form Error - Not Found Address': '抱歉，你所填寫的地址暫時不在配送範圍',
    'Form Error - Not Found Delivery':
      '抱歉，你所填寫的地址暫時不在配送範圍，請重新輸入後再選擇送貨方式',
    'Form Error - Card Type Not Support': '暫不支援您使用的信用卡別種類',
    'Credit Card Holder Name': '持卡人名字',
    'Credit Card Number': '信用卡號碼',
    'Credit Card Expiry Date': '有效日期 (MM/YY)',
    'Credit Card CVC': '安全碼 (CVC)',
    'SlPayment Credit Card Holder Name Hint': '與信用卡上名稱相同',
    'SlPayment Credit Card Expiry Date': '有效日期',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      '對不起，訂單出現問題，請檢查資料填寫正確後再試或聯絡店家。謝謝！',
    'SLPayment Check Credit Card Info Error':
      '請先確認卡片資訊，如確認輸入資料正確，請向發卡行確認卡片授權狀況或聯繫店家',
    'SLPayment Atome MinAmount Error':
      '訂單金額不符付款方式限額，請調整購物車內容或切換其他付款方式',
    'SlPayment General Error': '付款失敗，請重新再試',
    'Payment 3D Verification Failed':
      '對不起，3D 驗證失敗導致訂單失敗，請再試一次或選擇其他付款方式，謝謝！',
    'CVC Hint': '一般信用卡於卡背後顯示3位安全碼',
    'Product Quantity Validation - Sold Out': '售完，<1>請移除商品</1>',
    'Product Quantity Validation - Invalid Format': '需 ≥ 1',
    'Product Quantity Validation - Low Inventory': '庫存只剩 {{ value }} 件',
    'Product Quantity Validation - Purchase Limit':
      '每筆訂單限購 {{ value }} 件',
    'Product Quantity Validation - Quantity Over Inventory': '超過庫存',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      '每位顧客限購 {{ limit }} 件',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      '每位顧客限購 {{ limit }} 件\n您只能再購買 {{ left }} 件',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      '每位顧客限購 {{ limit }} 件\n您已購買達上限',
    'Product Quantity Validation - Invalid': '已失效，<1>請移除商品</1>',
    'Product Quantity Validation - Insufficient Point':
      '點數不足，<1>請移除商品</1>',
    'Points used to redeem gifts': '點數兌換贈品',
    'Points earned after days': '取貨後 {{number}} 天獲得點數',
    'Points earned after order completed': '訂單完成後獲得點數',
    'Credits earned after order completed': '訂單完成後獲得購物金',
    Points: '點',
    Credits: '購物金',
    'Free Gift': '贈品',
    'Remove Product Popup - title': '移除商品',
    'Remove Product Popup - content': '確定要移除 {{ itemName }} 嗎？',
    'Remove Product Popup - cancel': '取消',
    'Remove Product Popup - remove': '移除',
    'Add-On Item Validation - Purchase Limit': '加購品數量不可超過主商品',
    'Product(s) ordered from livestream are reserved':
      '直播商品限時保留庫存，倒數<0>{{ countdown }}</0>把握機會帶回家！',
    'Live Stream limited time reservation': '直播限時保留 {{ qty }}件',
    'Live Stream limited time reservation (separator)': '、',
    'Live Stream limited time reservation (unit)': '{{ qty }}件',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `保留至 {{ endDateFormat }}
      ({{ startDateFormat }} 開始的直播：
      {{ title }})
      根據你的當地時區顯示 (GMT{{ offset }})`,
    'Inclusive Tax Hint': '已含稅 {{taxName}} {{taxRate}}%：{{taxAmount}}',
    'SL FPX Payment Hint':
      '您正在使用 SHOPLINE Payments 結帳，完成付款代表您同意 SHOPLINE Payments 的<a href="/about/terms" target="_blank">服務條款</a>及FPX的<a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">服務條款</a>',
    'SL FPX Checkout Term':
      '下一步將會連接到銀行頁面進行付款，完成後請記得按下確認付款，返回訂單頁面確認訂購完成。',
    'Select Bank': '請選擇銀行',
    'Field (optional)': '{{fieldName}} (選填)',
    'Field - gender': '性別',
    'Field - birthday': '生日',
    'Gender - male': '男',
    'Gender - female': '女',
    'Gender - other': '不透露',
    'Birthday - hint': '你需達到一定年齡才能於網店註冊及購物。',
    'Birthday - year': '年',
    'Birthday - month': '月',
    'Birthday - date': '日',
    'Saved Addresses - new': '使用新地址',
    'Saved Addresses - default': '預設地址',
    'Saved Addresses - saved address': '已儲存地址',
    'Saved Addresses - save address': '儲存這個送貨地址',
    'Add To Cart': '加入購物車',
    'Add To Cart Success': '已經成功加入到購物車',
    'Addon Reminder': '下方還有商品可前往加購',
    'Addon Limit': '限購 {{limit}} 件',
    'Reach Purchase Limit': '已達購買上限',
    'Sold Out': '售完',
    'Addon Min Price': '訂單小計滿 {{minPrice}} 即以優惠價加購下列商品！',
    'Addon Item': '加購品',
    'Cart Over Limit Error': '商品總數的上限為 100 件，請調整數量再點擊購買',
    Cancel: '取消',
    Continue: '繼續',
    'Auto Fill - Title': '輸入手機以進行快速結帳',
    'Auto Fill - Phone Error': '請輸入正確的格式',
    'Auto Fill - Toast': '沒有偵測到上一筆訂單的紀錄，將不會帶入快速結帳資料',
    QuickRegistrations_creditValueDescription:
      '請協助填寫以下會員資訊，填寫完畢將可獲得 ${{ creditValue }} 購物金',
    QuickRegistrations_isVerified: '已驗證',
    QuickRegistrations_isUnverified: '未驗證',
    QuickRegistrations_Input_Phone_Invalid_Format: '請輸入正確的手機格式',
    QuickRegistrations_Input_Email_Invalid_Format: '請輸入正確的電郵地址',
    QuickRegistrations_Input_Password_Required: '網店登入密碼必填',
    QuickRegistrations_Input_Password_Too_Short: '密碼太短',
    QuickRegistrations_Input_Password_Too_Long: '密碼太長',
    QuickRegistrations_Input_Password_Complexity:
      'Password 最少8個字元（不能有空格\nPassword 最少1個大寫英文字母\nPassword 最少1個小寫英文字母\nPassword 最少1個數字\nPassword 最少1個特殊符號(例 ! # $ ^ *)',
    QuickRegistrations_Input_Phone_Placeholder: '請輸入手機(必填)',
    QuickRegistrations_Input_Email_Placeholder: '請輸入Email',
    QuickRegistrations_Input_Password_Placeholder: '網店登入密碼 (至少 8 字元)',
    QuickRegistrations_Input_Name_Placeholder: '請輸入全名(必填)',
    QuickRegistrations_Input_Phone_Label: '手機號碼',
    QuickRegistrations_Input_Email_Label: 'Email',
    QuickRegistrations_Input_Password_Label: '密碼',
    QuickRegistrations_Input_Name_Label: '會員姓名',
    QuickRegistrations_Input_Birthday: '生日',
    QuickRegistrations_Input_Birthday_Year_Placeholder: '年',
    QuickRegistrations_Input_Birthday_Month_Placeholder: '月',
    QuickRegistrations_Input_Birthday_Day_Placeholder: '日',
    QuickRegistrations_Input_Gender_Female: '女性',
    QuickRegistrations_Input_Gender_Male: '男性',
    QuickRegistrations_Input_Gender_Other: '不透露',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }}(必填)',
    QuickRegistrations_Submit: '送出',
    QuickRegistrations_Modal_getCreditSuccess: '成功領取購物金',
    QuickRegistrations_Modal_getCreditAlready: '已領取過購物金',
    QuickRegistrations_Modal_BindingLINEFailed_title: '綁定失敗',
    QuickRegistrations_Modal_BindingLINEFailed_body:
      '此 LINE 帳號曾綁定過官方 LINE 帳號，無法再次綁定',
    QuickRegistrations_Modal_Social_User_Binding_Error: '訂閱失敗，請聯絡店家',
    QuickRegistrations_Modal_Social_User_Double_Binding_Error:
      '訂閱失敗，此社群帳號已綁定其他顧客，請聯絡店家解除綁定',
    QuickRegistrations_Modal_Platform_Id_Different_Error:
      '訂閱失敗，請用註冊此會員的 LINE 帳號訂閱',
    QuickRegistrations_Modal_Platform_User_Id_Signed_Up_By_Others_Error:
      '訂閱失敗，此 LINE 帳號已被其他會員註冊使用，請用 LINE 登入該帳號再訂閱',
    QuickRegistrations_Modal_Line_Error: '訂閱失敗，請重新登入 LINE',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: '確定',
    QuickRegistrations_already_used: '已被其他會員使用',
    QuickRegistrations_custom_field_is_required: '{{ fieldName }} 為必填',
    QuickRegistrations_minimum_age_limit: '年齡限制須大於 {{ age }} 歲',
    QuickRegistrations_Member_Info_Hint:
      '填寫以下會員資訊：{{ fields }}，填寫完畢可獲得 {{ rewards }}',
    QuickRegistrations_Member_Info_Sent: '待資料檢查完成，將發放會員資料獎賞！',
    QuickRegistrations_Member_Info_Symbol: '、',
    QuickRegistrations_Member_Info_Name: '姓名',
    QuickRegistrations_Member_Info_Email: '電郵',
    QuickRegistrations_Member_Info_Mobile: '手機號碼',
    QuickRegistrations_Member_Info_Gender: '性別',
    QuickRegistrations_Member_Info_Birthday: '生日',
    QuickRegistrations_Member_Info_User_Credits: '${{ count }} 元購物金',
    QuickRegistrations_Member_Info_Member_Points: '{{ count }} 點會員點數',
    QuickRegistrations_Member_Info_Coupons: '{{ count }} 張優惠券',
  },
  widget: {
    Subscribed: '訂閱成功',
    'Invalid email': 'email 格式有誤',
    'Please try again': '請重試一次',
    'You have requested too many times, please try again later':
      '您已執行多次，請稍後再試。',
    'Clear All Filter': '清除全部',
  },
};
