import country from './country/en';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'Or fill in the information',
    'Form Validation - required': '{{fieldName}} is required',
    'Form Validation - max': '{{fieldName}} is too long',
    'Form Validation - maxLength': '{{fieldName}} is too long',
    'Form Validation - min': '{{fieldName}} is too short',
    'Form Validation - pattern': '{{fieldName}} is invalid',
    'Form Validation - validate': '{{fieldName}} is invalid',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} is incorrect, please check again',
    'Form Validation - terms':
      "You must agree with the shop's Terms of Use and Privacy Policy",
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      "I agree to the shop's <0>Terms of Use</0> and <2>Privacy Policy</2>",
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Terms: '<0>Terms of Use</0> and <2>Privacy Policy</2>',
    'Ask Signin': 'Already Registered?',
    'Member Login': 'Log in',
    Login: 'Log in',
    'Customer Information': 'Customer Info',
    'Full Name': 'Full Name',
    'Email Address': 'Email Address',
    'Email Address Optional': 'Email Address (optional)',
    'Create an account with shop': 'Create an account with {{shopName}} shop',
    'Send email updates and promotions':
      'I accept to receive shop updates, promotions and other marketing information from {{merchantName}}',
    'Shipping Address': 'Shipping Address',
    'Recipient Name': 'Recipient Name',
    'Recipient Contact Number': 'Recipient phone number',
    'Customer Contact Number': 'Customer contact number',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (optional)',
    'Address Line 2 DE': 'Additional address (optional)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'Payment Detail',
    'Payment Fee': 'Payment Fee',
    'Total Order Over Hint':
      'This order must meet the minimum amount {{amount}} in order to proceed to checkout.',
    'Delivery Method': 'Delivery Method',
    'Delivery Fee': 'Delivery Fee',
    'Remarks for Shop': 'Other Info',
    'Remarks for Shop Placeholder':
      'Leave order remarks for the Shop (optional)',
    'Cart Title': 'Product List',
    'Cart Total': 'Total',
    'Cart Subtotals subtotal': 'Item Subtotal',
    'Cart Subtotals discount': 'Discount Applied',
    'Cart Subtotals total_tax_fee': 'Tax fee',
    'Cart Subtotals applied_user_credits': 'Applied Store Credit',
    'Cart Subtotals delivery_fee': 'Delivery Fee',
    'Cart Subtotals payment_fee': 'Payment Fee',
    'Cart Subtotals custom_discount': 'Discount (custom)',
    'Cart Subtotals applied_member_point_redeem_to_cash':
      'Applied Point Discount',
    'Cart delivery_fee Free': 'Free',
    'Cart delivery_fee Partial Free': 'Partial Free Shipping',
    'Cart Coupon code apply success': 'Coupon Applied',
    'Cart Affiliate code apply success': 'Affiliate Applied',
    'Place Order': 'Place Order',
    'Promotion Label - Promotion': 'Promotion',
    'Promotion Label - Subscription Promotion':
      'Product Subscription Promotion',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Point Reward': 'Reward Points',
    'Promotion Label - Earn Purchase Points': 'Earning Points',
    'Promotion Label - Bundle Pricing': 'Bundle',
    'Promotion Label - Bundle Group': 'A + B',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'Points Campaign',
    'Promotion Label - Member Tier Promotion': 'Member Offer',
    'Custom Discount Item Label': 'Custom Discount',
    'Max Discount Price custom_discount': 'up to',
    'Apply Code': 'Validate',
    'Enter Coupon Code': 'Enter coupon code',
    'Enter Affiliate Code': 'Apply An Affiliate Code',
    'Free Checkout': 'Free Checkout',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'Cardholder Name',
    'Credit Card Number': 'Credit Card Number',
    'Credit Card Expiry Date': 'Expiry Date (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint': 'Same as the full name on card',
    'SlPayment Credit Card Expiry Date': 'Expiry Date',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'MM',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'YY',
    'Payment General Error':
      'Error processing order, please verify your information and try again. If the problem persists, please email shop owner for details.',
    'SLPayment Check Credit Card Info Error':
      'Please make sure you provided the correct information of your credit card, or you may contact with merchants or card issuing banks.',
    'SLPayment Atome MinAmount Error':
      'The order amount does not meet the payment method minimum, please modify the content of the shopping cart or switch to another payment method.',
    'SlPayment General Error': 'This transaction failed, please try again.',
    'Payment 3D Verification Failed':
      '3D secure verification failed, please try again or select other payment methods, thank you!',
    'CVC Hint': '3-digit security code usually found on the back of your card.',
    'Product Quantity Validation - Sold Out':
      'Sold out, please <1>remove it</1>',
    'Product Quantity Validation - Invalid Format': 'Must ≥ 1',
    'Product Quantity Validation - Low Inventory': 'Left {{ value }} only',
    'Product Quantity Validation - Purchase Limit':
      'Limit {{ value }} per order',
    'Product Quantity Validation - Quantity Over Inventory': 'Not enough stock',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      'Limit {{ limit }} per customer',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      'Limit {{ limit }} per customer. You can only purchase {{ left }} more.',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      'Limit {{ limit }} per customer. You have already purchased to limit.',
    'Product Quantity Validation - Invalid': 'Invalid, please <1>remove it</1>',
    'Product Quantity Validation - Insufficient Point':
      'Not enough points, <1>remove it</1>',
    'Points used to redeem gifts': 'Points used to redeem gifts',
    'Points earned after days': 'Points earned {{number}} day(s) after pick up',
    'Points earned after order completed': 'Points earned after completed',
    'Credits earned after order completed': 'Credits earned after completed',
    Points: 'point(s)',
    Credits: 'credit(s)',
    'Free Gift': 'Free Gift',
    'Remove Product Popup - title': 'Remove product',
    'Remove Product Popup - content':
      'Are you sure to remove {{ itemName }} from your shopping cart?',
    'Remove Product Popup - cancel': 'Cancel',
    'Remove Product Popup - remove': 'Remove',
    'Add-On Item Validation - Purchase Limit':
      'The quantity of add-on item should not over its main product',
    'Product(s) ordered from livestream are reserved':
      'Product(s) ordered from livestream are reserved,<0>{{ countdown }}</0>left to bring it home!',
    'Live Stream limited time reservation':
      'Live Stream limited time reservation {{ qty }} piece(s)',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} piece(s)',
    'Tooltip date format': 'YYYY/MM/DD HH:mm',
    'Reserve till': `Reserve till {{ endDateFormat }}
      (Live streamed at {{ startDateFormat }}:
      {{ title }})
      Display according to your local time zone (GMT{{ offset }})`,
    'Inclusive Tax Hint':
      '{{taxName}} {{taxRate}}% Tax Included: {{taxAmount}}',
    'SL FPX Payment Hint':
      'You are using SHOPLINE Payments to checkout now. By completing the payment, you agree to the <a href="/about/terms" target="_blank">Terms of Service</a> of SHOPLINE Payments and <a href="https://www.mepsfpx.com.my/FPXMain/termsAndConditions.jsp" target="_blank">Terms of Services</a> of FPX',
    'SL FPX Checkout Term':
      "You will be redirected to the bank's website to finish the payment. Please remember to click the confirm payment button to return to the order page to make sure the order is placed.",
    'Select Bank': 'Select Bank',
    'Field (optional)': '{{fieldName}} (optional)',
    'Field - gender': 'Gender',
    'Field - birthday': 'Birthday',
    'Gender - male': 'Male',
    'Gender - female': 'Female',
    'Gender - other': 'Other',
    'Birthday - hint':
      'You should over certain age to register and shop at the online store.',
    'Birthday - year': 'YYYY',
    'Birthday - month': 'MM',
    'Birthday - date': 'DD',
    'Saved Addresses - new': 'New Address',
    'Saved Addresses - default': 'Default Address',
    'Saved Addresses - saved address': 'Saved Addresses',
    'Saved Addresses - save address':
      'Save this new address into my address book',
    'Add To Cart': 'ADD TO CART',
    'Add To Cart Success': 'The item was successfully added to your cart',
    'Addon Reminder': "Don't miss out on our add-on products below!",
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': 'REACH LIMIT',
    'Sold Out': 'SOLD OUT',
    'Addon Min Price': 'Get Add-on Items with purchase over {{minPrice}}',
    'Addon Item': 'Add-on Item',
    'Cart Over Limit Error':
      'The maximum number of items is 100, please adjust the quantity and purchase again',
    Cancel: 'Cancel',
    Continue: 'Continue',
    'Auto Fill - Title': 'Enter your phone for fast checkout',
    'Auto Fill - Phone Error': 'Please enter the correct format',
    'Auto Fill - Toast':
      'If there is no record of the last order detected, the fast checkout data will not be brought in',
    QuickRegistrations_creditValueDescription:
      'Please fill in the member information. You will get ${{ creditValue }} store credits after filling it out',
    QuickRegistrations_isVerified: 'Verified',
    QuickRegistrations_isUnverified: 'Unverified',
    QuickRegistrations_Input_Phone_Invalid_Format:
      'Please enter the correct phone format',
    QuickRegistrations_Input_Email_Invalid_Format:
      'Please enter a valid email address.',
    QuickRegistrations_Input_Password_Required: 'Login password is required',
    QuickRegistrations_Input_Password_Too_Short: 'Password is too short',
    QuickRegistrations_Input_Password_Too_Long: 'Password is too long',
    QuickRegistrations_Input_Password_Complexity:
      'Password At least 8 characters with no space\nPassword At least 1 upper case letter\nPassword At least 1 lower case letter\nPassword At least 1 number\nPassword At least 1 of the following special characters from(e.g  ! # $ ^ * )',
    QuickRegistrations_Input_Phone_Placeholder: 'Enter a phone number',
    QuickRegistrations_Input_Email_Placeholder: 'Please enter email',
    QuickRegistrations_Input_Password_Placeholder: 'Login password',
    QuickRegistrations_Input_Name_Placeholder: 'Please enter full name',
    QuickRegistrations_Input_Phone_Label: 'Phone Number',
    QuickRegistrations_Input_Email_Label: 'Email',
    QuickRegistrations_Input_Password_Label: 'Password',
    QuickRegistrations_Input_Name_Label: 'Member Name',
    QuickRegistrations_Input_Birthday: 'Birthday',
    QuickRegistrations_Input_Birthday_Year_Placeholder: 'Year',
    QuickRegistrations_Input_Birthday_Month_Placeholder: 'Month',
    QuickRegistrations_Input_Birthday_Day_Placeholder: 'Day',
    QuickRegistrations_Input_Gender_Female: 'Female',
    QuickRegistrations_Input_Gender_Male: 'Male',
    QuickRegistrations_Input_Gender_Other: 'Undisclosed',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }}(required)',
    QuickRegistrations_Submit: 'Send',
    QuickRegistrations_Modal_getCreditSuccess:
      'Store credit received successfully',
    QuickRegistrations_Modal_getCreditAlready: 'Store credit already received',
    QuickRegistrations_Modal_BindingLINEFailed_title: 'Failed to bind',
    QuickRegistrations_Modal_Social_User_Binding_Error:
      'Failed to subscribe. Please contact the store.',
    QuickRegistrations_Modal_Social_User_Double_Binding_Error:
      'Failed to subscribe. This social account is already bound to another member. Please contact the store to unbind.',
    QuickRegistrations_Modal_Platform_Id_Different_Error:
      'Failed to subscribe. Please log in with the LINE account of the member account.',
    QuickRegistrations_Modal_Platform_User_Id_Signed_Up_By_Others_Error:
      'Failed to subscribe. This LINE account is already used for registration by another member. Please log in with the LINE account and try again.',
    QuickRegistrations_Modal_Line_Error:
      'Failed to subscribe. Please log in to LINE again.',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: 'Confirm',
    QuickRegistrations_already_used: 'It is registered by another member',
    QuickRegistrations_custom_field_is_required: '{{ fieldName }} is required.',
    QuickRegistrations_minimum_age_limit: 'limit age {{ age }}',
    QuickRegistrations_Member_Info_Hint:
      'Fill in the following member information: {{ fields }}, you can get {{ rewards }} after filling the profile information',
    QuickRegistrations_Member_Info_Sent:
      'After the information check is completed, Member Profile Completion Reward will be issued!',
    QuickRegistrations_Member_Info_Symbol: ', ',
    QuickRegistrations_Member_Info_Name: 'Name',
    QuickRegistrations_Member_Info_Email: 'Email',
    QuickRegistrations_Member_Info_Mobile: 'Mobile number',
    QuickRegistrations_Member_Info_Gender: 'Gender',
    QuickRegistrations_Member_Info_Birthday: 'Birthday',
    QuickRegistrations_Member_Info_User_Credits: '${{ count }} store credits',
    QuickRegistrations_Member_Info_Member_Points: '{{ count }} member points',
    QuickRegistrations_Member_Info_Coupons: '{{ count }} coupons',
  },
  widget: {
    Subscribed: 'Subscribed',
    'Invalid email': 'Invalid email',
    'Please try again': 'Please try again',
    'You have requested too many times, please try again later':
      'You have requested too many times, please try again later.',
    'Clear All Filter': 'Clear all',
  },
};
