import country from './country/vn';

export default {
  translation: {
    ...country,
    'Express checkout': 'Express checkout',
    'Or fill in the information': 'Hoặc điền vào thông tin bên dưới',
    'Form Validation - required': '{{fieldName}} là bắt buộc',
    'Form Validation - max': '{{fieldName}} quá dài',
    'Form Validation - maxLength': '{{fieldName}} quá dài',
    'Form Validation - min': '{{fieldName}} quá ngắn',
    'Form Validation - pattern': '{{fieldName}} của người nhận không hợp lệ',
    'Form Validation - validate': '{{fieldName}} của người nhận không hợp lệ',
    'Form Validation - validate - minAge':
      'Birthday is invalid, should over {{minAge}} years old.',
    'Form Validation - phone - validate - pattern':
      '{{fieldName}} không chính xác, vui lòng kiểm tra lại',
    'Form Validation - terms':
      'Bạn phải đồng ý với các điều khoản và điều kiện để tiếp tục.',
    'Form Action Deleting': 'deleting',
    IAgreeTerms:
      'Tôi đồng ý với <0>Điều khoản sử dụng</0> và <2>Chính sách quyền riêng tư</2> của cửa hàng',
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Terms: '<0>Điều khoản sử dụng</0> và <2>Chính sách quyền riêng tư</2>',
    'Ask Signin': 'Đã đăng ký?',
    'Member Login': 'Đăng nhập',
    Login: 'Đăng nhập',
    'Customer Information': 'Thông tin khách hàng',
    'Full Name': 'Họ và tên',
    'Email Address': 'Địa chỉ email',
    'Create an account with shop':
      'Tạo một tài khoản với {{shopName}} cửa hàng',
    'Send email updates and promotions':
      'I accept to receive shop updates, promotions and other marketing information from {{merchantName}}',
    'Shipping Address': 'Chi tiết giao hàng',
    'Recipient Name': 'Tên người nhận',
    'Recipient Contact Number': 'Số điện thoại người nhận',
    'Address Line 1': 'Address',
    'Address Line 1 DE': 'Street and house number',
    'Address Line 2': 'Apartment, suite, etc. (Không bắt buộc)',
    'Address Line 2 DE': 'Additional address (Không bắt buộc)',
    Address: 'Address',
    'Shipping Destination': 'Shipping Destination',
    'US Shipping Destination': 'Country/Region',
    City: 'City',
    State: 'State/Province/Region',
    'US State': 'State',
    Zipcode: 'ZIP / Postal Code',
    'Zipcode GB': 'Postcode',
    'Zipcode FR': 'Postal Code',
    'Zipcode DE': 'Postal code',
    'Payment Detail': 'Chi tiết thanh toán',
    'Payment Fee': 'Phí thanh toán',
    'Total Order Over Hint':
      'Đơn hàng này phải đáp ứng số tiền đặt hàng tối thiểu {{amount}} để có thể tiếp tục thanh toán.',
    'Delivery Method': 'Phương thức giao hàng',
    'Delivery Fee': 'Phí giao hàng',
    'Remarks for Shop': 'Thông tin khác',
    'Remarks for Shop Placeholder': 'Để lại ghi chú cho đơn hàng',
    'Cart Title': 'Danh sách sản phẩm',
    'Cart Total': 'Tổng tiền hàng',
    'Cart Subtotals subtotal': 'Tổng các mục',
    'Cart Subtotals discount': 'Áp dụng giảm giá',
    'Cart Subtotals total_tax_fee': 'Phí thuế',
    'Cart Subtotals applied_user_credits': 'Áp dụng điểm thưởng cửa hàng',
    'Cart Subtotals delivery_fee': 'Phí giao hàng',
    'Cart Subtotals payment_fee': 'Phí thanh toán',
    'Cart Subtotals custom_discount': 'Giám giá (tuỳ chỉnh)',
    'Cart Subtotals applied_member_point_redeem_to_cash':
      'Đã áp dụng điểm thành viên',
    'Cart delivery_fee Free': 'Miễn phí giao hàng',
    'Cart delivery_fee Partial Free': 'Miễn phí giao hàng một phần',
    'Cart Coupon code apply success': 'Coupon Applied',
    'Cart Affiliate code apply success': 'Affiliate Applied',
    'Place Order': 'Đặt hàng',
    'Promotion Label - Promotion': 'chương trình khuyến mãi',
    'Promotion Label - Subscription Promotion':
      'Khuyến mãi sản phẩm đăng ký định kỳ',
    'Promotion Label - Credit Reward': 'Reward Credits',
    'Promotion Label - Point Reward': 'Reward Points',
    'Promotion Label - Earn Purchase Points': 'Earning Points',
    'Promotion Label - Bundle Pricing': 'theo set',
    'Promotion Label - Bundle Group': 'Nhóm set',
    'Promotion Label - Buy X Get Y': 'Buy X Get Y',
    'Promotion Label - Member Point Redeem Gift': 'Điểm chiến dịch',
    'Promotion Label - Member Tier Promotion': 'Ưu đãi thành viên',
    'Custom Discount Item Label': 'Giảm giá tùy chỉnh',
    'Max Discount Price custom_discount': 'Giảm giá tối đa',
    'Apply Code': 'Xác minh',
    'Enter Coupon Code': 'Nhập mã giảm giá',
    'Enter Affiliate Code': 'Áp dụng mã Affiliate',
    'Free Checkout': 'Miễn phí Thanh toán',
    'Form Error - Not Found Address':
      'Sorry, the address you have filled in is out of the delivery scope.',
    'Form Error - Not Found Delivery':
      'Sorry, the address you have filled in is out of the delivery scope.  Please choose another address.',
    'Form Error - Card Type Not Support':
      'The card type is not supported at this moment',
    'Credit Card Holder Name': 'Tên đầy đủ của chủ thẻ',
    'Credit Card Number': 'Số thẻ tín dụng',
    'Credit Card Expiry Date': 'Ngày hết hạn (MM/YY)',
    'Credit Card CVC': 'CVC',
    'SlPayment Credit Card Holder Name Hint':
      'Giống như họ tên đầy đủ trên thẻ',
    'SlPayment Credit Card Expiry Date': 'Ngày hết hạn',
    'SlPayment Credit Card Expiry Date Month Placeholder': 'Tháng',
    'SlPayment Credit Card Expiry Date Year Placeholder': 'Năm',
    'Payment General Error':
      'Lỗi xử lý đơn đặt hàng, vui lòng xác minh thông tin của bạn và thử lại. Nếu sự cố vẫn tiếp diễn, vui lòng liên hệ với chủ cửa hàng để được hỗ trợ. Cảm ơn bạn!',
    'Payment 3D Verification Failed':
      'Xác minh an toàn 3D không thành công, vui lòng thử lại hoặc chọn phương thức thanh toán khác, cảm ơn bạn!',
    'CVC Hint': 'mã bảo vệ 3 chữ số thường được tìm thấy trên mặt sau của thẻ.',
    'Product Quantity Validation - Sold Out':
      'Sản phẩm đã bán hết, <1>vui lòng xóa bỏ ra khỏi lựa chọn của bạn</1>',
    'Product Quantity Validation - Invalid Format': 'Phải ≥ 1',
    'Product Quantity Validation - Low Inventory':
      'Chỉ còn lại {{ value }} sản phẩm',
    'Product Quantity Validation - Purchase Limit':
      'Giới hạn {{ value }} sản phẩm trên một đơn hàng',
    'Product Quantity Validation - Quantity Over Inventory':
      'Hàng tồn kho không đủ',
    'Product Quantity Validation - Customer Purchase Limit Without Record':
      'Giới hạn {{ limit }} sản phẩm cho mỗi khách hàng',
    'Product Quantity Validation - Customer Purchase Limit With Record':
      'Giới hạn {{ limit }} sản phẩm cho mỗi khách hàng. Bạn chỉ có thể mua được {{ left }} sản phẩm nữa.',
    'Product Quantity Validation - Customer Purchase Limit Exceeded':
      'Giới hạn {{ limit }} sản phẩm cho mỗi khách hàng. Sản phẩm đã đạt số lượng giới hạn.',
    'Product Quantity Validation - Invalid':
      'Vô hiệu lực, <1>vui lòng xóa bỏ ra khỏi lựa chọn của bạn</1>',
    'Points used to redeem gifts': 'Số điểm dùng để đổi quà tặng',
    'Points earned after days':
      'Số điểm kiếm được sau khi nhận hàng {{number}} ngày',
    'Points earned after order completed': 'Points earned after completed',
    'Credits earned after order completed': 'Credits earned after completed',
    Points: 'Điểm',
    Credits: 'credit(s)',
    'Free Gift': 'Quà miễn phí',
    'Remove Product Popup - title': 'Xóa bỏ sản phẩm',
    'Remove Product Popup - content':
      'Bạn có chắc muốn Xóa bỏ sản phẩm {{ itemName }} ra khỏi giỏ hàng?',
    'Remove Product Popup - cancel': 'Hủy',
    'Remove Product Popup - remove': 'Xóa bỏ',
    'Add-On Item Validation - Purchase Limit':
      'Số lượng sản phẩm đi kèm không được vượt quá số lượng sản phẩm chính',
    'Product(s) ordered from livestream are reserved':
      '(Các) sản phẩm đặt từ buổi livestream đều được lên đơn, chỉ còn<0>{{ countdown }}</0>! thời gian để hoàn tất và mang tất cả về nhà!',
    'Live Stream limited time reservation':
      'Phát trực tiếp trong thời gian giới hạn đã giữ {{ qty }} sản phẩm',
    'Live Stream limited time reservation (separator)': ', ',
    'Live Stream limited time reservation (unit)': '{{qty}} sản phẩm',
    'Tooltip date format': 'HH:mm ngày DD/MM/YYYY',
    'Reserve till': `Giữ hàng đến {{ endDateFormat }}
      (Phát trực tiếp lúc {{ startDateFormat }}:
      {{ title }})
      Hiển thị theo múi giờ địa phương của bạn (GMT{{ offset }})`,
    'Inclusive Tax Hint':
      '{{taxName}} {{taxRate}}% Thuế đã bao gồm: {{taxAmount}}',
    'Field (optional)': '{{fieldName}} (Không bắt buộc)',
    'Field - gender': 'Giới tính',
    'Field - birthday': 'Ngày sinh',
    'Gender - male': 'Nam',
    'Gender - female': 'Nữ',
    'Gender - other': 'Không khai báo',
    'Saved Addresses - new': 'Địa chỉ mới',
    'Saved Addresses - default': 'Địa chỉ mặc định',
    'Saved Addresses - saved address': 'Địa chỉ đã lưu',
    'Saved Addresses - save address':
      'Lưu địa chỉ mới này vào sổ địa chỉ của tôi',
    'Add To Cart': 'THÊM VÀO GIỎ HÀNG',
    'Add To Cart Success': 'Sản phẩm đã được thêm vào giỏ hàng thành công',
    'Addon Reminder': 'Đừng bỏ lỡ các sản phẩm đính kèm bên dưới!',
    'Addon Limit': 'LIMIT {{limit}} PER ORDER',
    'Reach Purchase Limit': 'ĐẠT GIỚI HẠN',
    'Sold Out': 'HẾT HÀN',
    'Addon Min Price':
      'Nhận các sản phẩm đi kèm khi mua hàng trên {{minPrice}}',
    'Addon Item': 'Sản phẩm Bổ sung',
    'Cart Over Limit Error':
      'Tối đa 100 sản phẩm, vui lòng điều chỉnh lại số lượng mua hàng.',
    Cancel: 'Hủy bỏ',
    Continue: 'Tiếp tục',
    'Auto Fill - Title': 'Nhập điện thoại của bạn để thanh toán nhanh chóng',
    'Auto Fill - Phone Error': 'Vui lòng nhập đúng định dạng',
    'Auto Fill - Toast':
      'Nếu không có hồ sơ về đơn hàng cuối cùng được phát hiện, dữ liệu thanh toán nhanh sẽ không được đưa vào',
    QuickRegistrations_creditValueDescription:
      'Vui lòng điền thông tin thành viên. Bạn sẽ nhận được ${{ creditValue }} điểm tín dụng khi điền.',
    QuickRegistrations_isVerified: 'Đã xác minh',
    QuickRegistrations_isUnverified: 'Chưa xác minh',
    QuickRegistrations_Input_Phone_Invalid_Format:
      'Vui lòng nhập đúng định dạng số điện thoại',
    QuickRegistrations_Input_Email_Invalid_Format:
      'Vui lòng nhập một địa chỉ email hợp lệ.',
    QuickRegistrations_Input_Password_Required: 'Login password is required',
    QuickRegistrations_Input_Password_Too_Short: 'Password is too short',
    QuickRegistrations_Input_Password_Too_Long: 'Password is too long',
    QuickRegistrations_Input_Password_Complexity:
      'Password At least 8 characters with no space\nPassword At least 1 upper case letter\nPassword At least 1 lower case letter\nPassword At least 1 number\nPassword At least 1 of the following special characters from(e.g  ! # $ ^ * )',
    QuickRegistrations_Input_Phone_Placeholder: 'Vui lòng nhập số điện thoại',
    QuickRegistrations_Input_Email_Placeholder: 'Vui lòng nhập email',
    QuickRegistrations_Input_Password_Placeholder: 'Login password',
    QuickRegistrations_Input_Name_Placeholder: 'Vui lòng nhập họ tên',
    QuickRegistrations_Input_Phone_Label: 'Số điện thoại',
    QuickRegistrations_Input_Email_Label: 'Email',
    QuickRegistrations_Input_Password_Label: 'Password',
    QuickRegistrations_Input_Name_Label: 'Tên thành viên',
    QuickRegistrations_Input_Birthday: 'Ngày sinh',
    QuickRegistrations_Input_Birthday_Year_Placeholder: 'Năm',
    QuickRegistrations_Input_Birthday_Month_Placeholder: 'Tháng',
    QuickRegistrations_Input_Birthday_Day_Placeholder: 'Ngày',
    QuickRegistrations_Input_Gender_Female: 'Nữ',
    QuickRegistrations_Input_Gender_Male: 'Nam',
    QuickRegistrations_Input_Gender_Other: 'Không tiết lộ',
    QuickRegistrations_Required_Input_Placeholder: '{{ field }}(required)',
    QuickRegistrations_Submit: 'Gửi',
    QuickRegistrations_Modal_getCreditSuccess:
      'Đã nhận điểm tín dụng thành công',
    QuickRegistrations_Modal_getCreditAlready: 'Đã nhận điểm tín dụng',
    QuickRegistrations_Modal_BindingLINEFailed_title: 'Kết nối thất bại',
    QuickRegistrations_Modal_Social_User_Binding_Error:
      'Failed to subscribe. Please contact the store.',
    QuickRegistrations_Modal_Social_User_Double_Binding_Error:
      'Failed to subscribe. This social account is already bound to another member. Please contact the store to unbind.',
    QuickRegistrations_Modal_Platform_Id_Different_Error:
      'Failed to subscribe. Please log in with the LINE account of the member account.',
    QuickRegistrations_Modal_Platform_User_Id_Signed_Up_By_Others_Error:
      'Failed to subscribe. This LINE account is already used for registration by another member. Please log in with the LINE account and try again.',
    QuickRegistrations_Modal_Line_Error:
      'Failed to subscribe. Please log in to LINE again.',
    QuickRegistrations_Modal_BindingLINEFailed_confirm: 'Xác nhận',
    QuickRegistrations_already_used: 'Đã được thành viên khác đăng ký',
    QuickRegistrations_custom_field_is_required: '{{ fieldName }} là bắt buộc.',
    QuickRegistrations_minimum_age_limit:
      'Ngày sinh không hợp lệ, phải trên {{ age }} tuổi',
    QuickRegistrations_Member_Info_Hint:
      'Điền thông tin thành viên sau: {{ fields }}, bạn có thể nhận được {{ rewards }} sau khi điền thông tin hồ sơ',
    QuickRegistrations_Member_Info_Sent:
      'Sau khi kiểm tra thông tin hoàn tất, phần thưởng Hoàn thành Hồ sơ Thành viên sẽ được phát hành!',
    QuickRegistrations_Member_Info_Symbol: ', ',
    QuickRegistrations_Member_Info_Name: 'Tên',
    QuickRegistrations_Member_Info_Email: 'Email',
    QuickRegistrations_Member_Info_Mobile: 'Số điện thoại',
    QuickRegistrations_Member_Info_Gender: 'Giới tính',
    QuickRegistrations_Member_Info_Birthday: 'Sinh nhật',
    QuickRegistrations_Member_Info_User_Credits:
      '${{ count }} tín dụng cửa hàng',
    QuickRegistrations_Member_Info_Member_Points: '{{ count }} điểm thành viên',
    QuickRegistrations_Member_Info_Coupons: '{{ count }} phiếu giảm giá',
  },
  widget: {
    Subscribed: 'Đã đăng ký',
    'Invalid email': 'Email không khả dụng',
    'Please try again': 'Vui lòng thử lại',
    'You have requested too many times, please try again later':
      'Bạn đã yêu cầu quá nhiều lần, vui lòng thử lại sau.',
    'Clear All Filter': 'Xoá tất cả',
  },
};
